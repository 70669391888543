import { Injectable } from "@angular/core";
import { Auth } from "../models/auth";
import { Register } from "../models/register";
import { Observable, map, of, switchMap } from "rxjs";
import { BaseService } from "./base/base.service";
import { LocalStorageService } from "./base/localStorage.service";
import { ModelBaseService } from "./base/modelBase.service";
import { error } from "console";

@Injectable({
  providedIn: "root",
})
export class AuthService extends ModelBaseService<Auth> {
  /**
   *
   */
  constructor(
    public baseService: BaseService,
    private localStorageService: LocalStorageService
  ) {
    super(`auth`, baseService);
  }

  // Simule la connexion de l'utilisateur
  login(auth: Auth): Observable<any> {
    return this.baseService.post("auth-recruiters/login", auth).pipe(
      map(
        (response) => {
          this.localStorageService.saveToken(response.token);
          this.localStorageService.saveUser(response.user);
          // Vous pouvez retourner quelque chose ici si nécessaire
          return response;
        },
        (error) => {
          return error;
        }
      )
    );
  }

  register(user: Register): Observable<any> {
    return this.baseService.post("auth-recruiters/register", user);
  }

  forgetPassword(user: any): Observable<any> {
    return this.baseService.post("auth-recruiters/forgot-password", user);
  }

  resetPassword(resetToken: string, password: string): Observable<any> {
    const body = { password };
    return this.baseService.put(
      `auth-recruiters/reset-password`,
      resetToken,
      body
    );
  }

  confirmAccount(resetToken: string): Observable<any> {
    return this.baseService.getAll(
      `auth-recruiters/confirm-email/${resetToken}`
    );
  }

  // Simule la déconnexion de l'utilisateur
  logout(): void {
    this.localStorageService.destroyToken();
  }

  // Vérifie si l'utilisateur est authentifié
  isLoggedIn(): boolean {
    return this.localStorageService.isAuthenticated();
  }
}
