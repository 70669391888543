import { Routes } from "@angular/router";

export const content: Routes = [
    {
        path:'',
        loadChildren: () => import("../../admin/pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
    },
    {
        path:'admin-dashboard',
        loadChildren: () => import("../../admin/pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
    },
    {
        path: "candidate",
        loadChildren: () => import("../../admin/pages/candidate/candidate.module").then((m) => m.CandidateModule),
    },
    {
        path: "interview",
        loadChildren: () => import("../../admin/pages/interview/interview.module").then((m) => m.InterviewModule),
    },
    {
        path: "salaried",
        loadChildren: () => import("../../admin/pages/salaried/salaried.module").then((m) => m.SalariedModule),
    },
    {
        path: "project",
        loadChildren: () => import("../../admin/pages/project/project.module").then((m) => m.ProjectModule),
    },
    {
        path: "paiement",
        loadChildren: () => import("../../admin/pages/paiement/paiement.module").then((m) => m.PaiementModule),
    },
    {
        path: "user",
        loadChildren: () => import("../../admin/pages/user/user.module").then((m) => m.UserModule),
    },
    {
        path: "**",
        loadChildren: () => import("../../admin/pages/error/error.module").then((m) => m.ErrorModule),
    },
];
