import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      title: "Tableau de bord",
      icon: "home",
      type: "link",
      badgeType: "light-primary",
      active: false,
      path: "/admin-dashboard",
  },
  
  {
      headTitle1: "Candidats",
      headTitle2: "Gestion des candidats qualifiés",
  },
  {
      title: "Gestion des candidats",
      icon: "user",
      type: "sub",
      active: false,
      children: [
          {
              title: "Liste des candidats",
              icon: "stroke-icon",
              type: "link",
              active: false,
              path: "candidate/list-candidates",
          },
      ],
  },
 

{
  headTitle1: "Entretiens",
  headTitle2: "Gestion des entretiens",
},
{
  title: "Calendrier des entretiens",
  icon: "task",
  type: "sub",
  active: false,
  children: [
      {
          title: "Calendrier",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "interview/calendar",
      },
  ],
},
{
  headTitle1: "Mes salariés",
  headTitle2: "Gestion des employés et projets",
},
{
  title: "Gestion des salariés",
  icon: "form",
  type: "sub",
  active: false,
  children: [
      {
          title: "Liste des salariés",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "salaried/list-salarieds",
      },
  ],
},
{
  title: "Gestion des projets",
  icon: "sample-page",
  type: "sub",
  active: false,
  children: [
      {
          title: "Liste des projets",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "project/list-projects",
      },
  ],
},

  {
    headTitle1: "Mes paiements",
    headTitle2: "Gestion des paiements",
},
{
    title: "Liste des paiements",
    type: "link",
    icon: "social",
    badgeType: "light-primary",
    active: false,
    path: "paiement/list-paiements",

},
// {
//   headTitle1: "Paramétrage",
//   headTitle2: "Gestion du paramétrage",
// },
// {
//   title: "Mon paramétrage",
//   type: "sub",
//   icon: "social",
//   badgeType: "light-primary",
//   active: false,
//   children: [
//     {
//       title: "Liste des abonnements",
//       icon: "stroke-icon",
//       type: "link",
//       active: false,
//       path: "subscription/list-subscription",
//   },
     

//   ],
// },

  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
